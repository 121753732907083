.contactUs {
    box-sizing      : border-box;
    height          : 388px;
    background-color: #282333;
    display         : flex;
    justify-content : center;
    align-items     : center;
    flex-direction: column;
}

.contactUs .text {
    font-size  : 30px;
    font-weight: bold;
    margin-bottom: 10px;
}

.contactUs .email {
    font-size  : 32px;
    font-weight: bold;
    color      : #B6AAFF;
        flex-direction: column;
}