.container1 {
    margin    : auto;
    width     : 1280px;
    margin-top: 24px;
}

.container1 .content {
    background-image: url('../../assets/images/Rectangle.svg');
    height          : 452px;
    width           : 1196px;
    margin          : auto;
    display         : flex;
    justify-content : space-between;
}

.container1 .content div {
    flex: 1;
}

.container1 .content>div:last-child img {
    margin-top  : 102.13px;
    margin-right: 45px;
}

.container1 .content>div:first-child {
    text-align  : left;
    padding-left: 70.5px;
    padding-top : 73.13px;
}

.container1 .content>div:first-child .text {
    font-size   : 40px;
    font-weight : bold;
    margin-bottom: 10px;
    /* line-height : 48px;
    word-spacing: 0.43px; */
}

.container1 .content>div:first-child .last_text {
    font-size: 30px;
    color    : #F6E6D2;
    margin-top:70px;
}