.header {
    width : 1280px;
    height: 86px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px 42px;
    margin: auto;
    font-size: 16px;
    box-sizing: border-box;
}
.header a{
    color:#ffffff;
    text-decoration: none;
}