 

.App {
  background-color: #2E293A;
  height:100%;
  overflow: auto;
  color:#FFFFFF;
  text-align: center;
} 

html {
  background-color: #2E293A;
}

::-webkit-scrollbar {
  display: none;
}


