.container2 {
    width          : 1280px;
    box-sizing     : border-box;
    height         : 529px;
    display        : flex;
    align-items    : flex-end;
    justify-content: space-around;
    margin         : auto;
    padding        : 0 43px;
    padding-bottom : 76px;
    box-sizing     : border-box;
}

.container2 .item {
    width             : 237px;
    height            : 316px;
    border            : 1px solid #514B5E;
    border-radius     : 17px;
    padding           : 20px;
    text-align        : left;
    display           : flex;
    flex-direction    : column;
    box-sizing        : border-box;
    transition        : width 0.1s,height 0.1s;
    -webkit-transition: width 0.1s,height 0.1s;
}

.container2 .item .context {
    display: none;
    padding: 29px 0px 20px 0px;
}

.container2 .item:hover .context {
    font-size  : 12px;
    color      : #4B4187;
    display    : block;
    padding    : 29px 0px 20px 0px;
    line-height: 18px; 
}
 
.container2 .item:hover .context p {
    margin: 0px;
    /* word-break: break-all; */

    text-overflow: ellipsis;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 4; 

    overflow: hidden;
}

.container2 .item:hover {
    width           : 278px;
    height          : 381px;
    background-color: #B6AAFF;
    color           : #1A1925;
}

.container2 .item>div:first-child {
    flex: 1;
}



.container2 .item .item-head {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    font-size      : 12px;
    color          : #868490
}

.container2 .item:hover .item-head {
    color: #4B4187;
}

.container2 .item .item-head .imgIcon {
    width           : 12px;
    height          : 14px;
    background-image: url(../../assets/images/note_white.svg);
}

.container2 .item:hover .item-head .imgIcon {
    background-image: url(../../assets/images/note-black.svg);
}

.container2 .item .text {
    font-size  : 22px;
    font-weight: bold;
    text-align : left;
    margin-top : 37px;
}
.container2 .item .text p {
    margin: 0px;
    /* word-break: break-all; */

    text-overflow: ellipsis;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 3;  

    overflow: hidden;
}
.container2 .item:hover .text {
    font-size : 26px;
    margin-top: 43px;
}

.container2 .item .lineIcon {
    margin-top      : 28px;
    width           : 44px;
    height          : 3px;
    background-image: url(../../assets/images/line_white.svg);
}

.container2 .item:hover .lineIcon {
    margin-top      : 22px;
    background-image: url(../../assets/images/line_black.svg);
}

.container2 .item .readMore {
    height: 67px;

}

.container2 .item .readMore a {
    font-size  : 14px;
    font-weight: bold;
    color      : #ffffff;
}

.container2 .item:hover .readMore a {
    color: #4B4187;
}